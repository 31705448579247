<template>
  <v-navigation-drawer
    v-model="isShowSidebar"
    ref="jVNavigationDrawer"
    :rail="!isShowSidebar"
    :location="location"
    absolute
    :rail-width="1"
    :width="width"
    :border="0"
    :color="scssVariables.jBgBlock"
    :class="[
      'j-game-sidebar',
      {
        'j-game-sidebar--desktop': !isMobile,
        'j-game-sidebar--scroll-fade': isShowFade,
        'j-game-sidebar--scroll-no-fade': !isShowFade,
      },
    ]"
  >
    <client-only>
      <Teleport :to="isMobile ? '.v-navigation-drawer__content' : '.v-main'">
        <v-app-bar-nav-icon
          variant="flat"
          :ripple="false"
          icon="mdi-chevron-left"
          :elevation="0"
          :min-width="41"
          :height="80"
          :color="scssVariables.jBgBlockLight"
          :class="[
            'j-game-sidebar__btn',
            {
              'j-game-sidebar__btn--active': isShowSidebar,
              'j-game-sidebar__btn--desktop': !isMobile,
              'j-game-sidebar__btn--inner': !isMobile && isShowSidebar,
            },
          ]"
          @click.stop="toggleSidebar"
        />
      </Teleport>
    </client-only>
    <template #prepend>
      <v-tabs
        v-model="tab"
        :bg-color="scssVariables.jBgBlockLight"
        :height="68"
        class="j-game-sidebar__tabs"
        selected-class="j-game-sidebar__tab--active"
        :hide-slider="!isShowTournamentsTab"
      >
        <v-tab
          value="popular"
          :width="isShowTournamentsTab ? '50%' : '100%'"
          :class="['j-game-sidebar__tab', { 'j-game-sidebar__tab--desktop': !isMobile }]"
          :slider-color="scssVariables.jColorPrimary"
          :ripple="false"
        >
          <v-icon
            icon="custom:popularIconActive"
            size="x-large"
          />
          <general-text
            dictionary-key="cabinet_personal_data_popular"
            class="j-text--white font-weight-medium text-capitalize ml-3"
          />
        </v-tab>
        <template v-if="isShowTournamentsTab">
          <v-divider
            vertical
            class="border-opacity-100"
            :color="scssVariables.jTabBorder"
          />
          <v-tab
            value="tournaments"
            width="50%"
            :class="['j-game-sidebar__tab', { 'j-game-sidebar__tab--desktop': !isMobile }]"
            :slider-color="scssVariables.jColorPrimary"
            :ripple="false"
          >
            <v-icon
              icon="custom:tournamentsIconActive"
              size="x-large"
            />
            <general-text
              dictionary-key="general_tournaments"
              class="j-text--white font-weight-medium text-capitalize ml-3"
            />
          </v-tab>
        </template>
      </v-tabs>
    </template>
    <v-window v-model="tab">
      <v-window-item value="popular">
        <div
          v-for="(itemData, index) in popularGamesExcludingLaunched"
          :key="index"
          class="ml-6 mr-10"
        >
          <div class="d-flex align-center">
            <general-content-game-card
              :img-src="getGameImgSrc(itemData as GameItem)"
              :pretty-name="(itemData as GameItem).prettyName"
              :provider="(itemData as GameItem).gameProviderId"
              :allowed-mode="($route.params.mode as AllowedMode)"
              small-card
              :game-card-select-handler="resizeGameFrame"
            />
            <general-text
              :text="itemData.gameName"
              class="ml-4 j-text--fs-16 j-text--white font-weight-medium"
            />
          </div>
          <v-divider
            v-if="index !== getPopularGames.total - 1"
            class="border-opacity-100 my-4"
            :color="scssVariables.jBgBlockLight"
          />
        </div>
      </v-window-item>
      <v-window-item
        v-if="getTournamentByGame"
        value="tournaments"
      >
        <general-text
          :text="getTournamentByGame.name"
          class="j-text--fs-24 j-text--white text-center"
        />
        <general-text
          :dictionary-key="`tournaments_${getTournamentByGame.tournamentType.toLowerCase()}`"
          class="j-text--fs-16 j-text--main-gradient text-center mt-2"
        />
        <div class="j-game-sidebar-tournament-prize ma-auto d-flex flex-column align-center justify-center">
          <v-img
            :src="images['tournaments-active']"
            :width="32"
            :max-height="32"
            alt="tournaments-active"
          />
          <general-text
            dictionary-key="tournaments_prize_pool"
            class="j-text--white j-text--fs-20 text-capitalize mt-4"
          />
          <general-text
            :text="`${getTournamentByGame.prizeFund}${
              CURRENCIES_SYMBOL[getTournamentByGame.currency as keyof typeof CURRENCIES_SYMBOL]
            }`"
            class="j-text--white j-text--fs-24"
          />
        </div>
        <general-table
          v-if="getTournamentByGame?.playerInTournament"
          class="mx-2"
          :headers="TABLES_ITEMS.gameStandings.headers"
          :items="formattedPrizePlaces"
        />
      </v-window-item>
    </v-window>
    <template #append>
      <div v-if="tab !== 'tournaments'">
        <v-btn
          v-if="isShowPayBtn"
          :to="linkGameReal"
          variant="flat"
          :color="scssVariables.jBtnRegular"
          :height="48"
          class="text-none text-body-1 pa-3 mx-6 j-btn j-btn--gradient-main d-block"
        >
          {{ $t('game_play_money') }}
        </v-btn>
      </div>
      <div v-else>
        <div
          v-if="!getTournamentByGame?.playerInTournament"
          class="mx-6"
        >
          <v-btn
            variant="flat"
            size="large"
            :color="scssVariables.jBtnRegular"
            :height="48"
            class="text-none text-body-1 px-6 j-btn j-btn--gradient-main w-100"
            @click="joinTournamentHandler(getTournamentByGame?.id)"
          >
            {{ $t('tournaments_participate') }}
          </v-btn>
        </div>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { VNavigationDrawer } from 'vuetify/lib/components/index.mjs';
import {
  REQUIRED_AUTH_PAGES_WITH_CHILDREN,
  GAMES_TYPES,
  PLATFORM,
  GAME_TYPES,
  CURRENCIES_SYMBOL,
} from '~/constants/general';
import { TABLES_ITEMS } from '~/components/JSidebarGame/constants';
import { useGamesStore } from '~/stores/games';
import { useTournamentsStore } from '~/stores/tournaments';
import { useAuthStore } from '~/stores/auth';
import type { GameItem } from '~/types/general/game';
import type { AllowedMode } from '~/types/components/General/ContentGameCard';

const { $eventEmitter } = useNuxtApp();
const images = useAssetsImages();
const authStore = useAuthStore();
const { getUserData } = storeToRefs(authStore);
const gamesStore = useGamesStore();
const tournamentsStore = useTournamentsStore();
const {
 getPopularGames,
 getPopularGamesRemoteStorageHost,
 getGameData,
} = storeToRefs(gamesStore);
const { getTournamentByGame } = storeToRefs(tournamentsStore);
const { fetchGamesByType } = gamesStore;
const {
  fetchTournamentByGame,
  joinTournament,
} = tournamentsStore;
const popularGamesExcludingLaunched = computed(
  () => getPopularGames.value.items.filter((gameData) => gameData.prettyName !== route.params.gamePrettyName),
);
const getGameImgSrc = (itemData: GameItem) => {
  const appStorageSrc = `/images/games/${itemData.provider}/${itemData?.gameCode}.jpg`;

  return itemData?.iconPath
    ? `${getPopularGamesRemoteStorageHost.value}${itemData?.iconPath}`
    : appStorageSrc;
};
const { isMobileOrTablet } = useDevice();
const currentPlatform = computed(() =>
  isMobileOrTablet
    ? PLATFORM.mobile
    : PLATFORM.desktop,
);
const scssVariables = useScssVariables();
const isMobile = inject<Ref<boolean>>('isMobile');
const isShowSidebar = inject<Ref<boolean>>('isShowGamePageSidebar');
const isScrollEvent = ref(false);
const jVNavigationDrawer: Ref<null | VNavigationDrawer> = ref(null);
const isShowFade = computed(
  () => tab.value !== 'tournaments' && isShowSidebar?.value && !isMobile?.value && isScrollEvent.value,
);
const route = useRoute();
const location = computed(() => isMobile?.value
  ? 'bottom'
  : 'right');
const width = computed(() => isMobile?.value
  ? 428
  : 361);
const isShowPayBtn = computed(() =>
  isMobile?.value && route.params.mode === REQUIRED_AUTH_PAGES_WITH_CHILDREN.game.fun);
const isShowTournamentsTab = computed(() => getUserData.value.loggedIn && getTournamentByGame.value);
const linkGameReal = computed(
  () => `/game/${route.params.gamePrettyName}/${REQUIRED_AUTH_PAGES_WITH_CHILDREN.game.real}`,
);
const tab = ref(null);
const loadPopularGames = async () => {
  const fetchPopularGamesParams = {
    limit: 11,
    offset: 0,
    platform: currentPlatform.value,
  };

  await fetchGamesByType(fetchPopularGamesParams, GAMES_TYPES.popular, false);
};
const loadTournamentByGame = async () => {
  const gameName = getGameData.value.gameCode;
  const fetchTournamentByGameParams = {
    gameType: GAME_TYPES.external,
    platform: currentPlatform.value,
  };

  await fetchTournamentByGame(gameName, fetchTournamentByGameParams);
};
const joinTournamentHandler = async (tournamentId: number | undefined) => {
  if (tournamentId) {
    await joinTournament(tournamentId);
    await loadTournamentByGame();
  }
};
const formattedPrizePlaces = computed(() => {
  if (Array.isArray(getTournamentByGame?.value?.standings?.prizePlaces)
    && getTournamentByGame?.value?.standings?.prizePlaces.length) {
    return getTournamentByGame?.value?.standings?.prizePlaces.map((item) => {
      const contentClass = item.currentUser
        ? 'orange'
        : '';

      return {
        isHighlight: item.currentUser,
        place: item.place,
        playerName: {
          value: item.playerName,
          contentClass,
        },
        score: {
          value: item.score,
          contentClass,
        },
      };
    });
  }

  return new Array(3).fill({
    place: '',
    playerName: '',
    score: '',
  });
});
const toggleSidebar = () => {
  isShowSidebar!.value = !isShowSidebar!.value;
  if (!isMobile?.value) {
    $eventEmitter.emit('toggle-game-page-sidebar');
  }
};
const resizeGameFrame = () => $eventEmitter.emit('resize-game-frame', true);
const contentScrollHandler = (event: Event) => {
  switch(event.type) {
    case 'scroll':
      return isScrollEvent.value = true;
    case 'scrollend':
      return isScrollEvent.value = false;
  }
};
const initialScrollListener = () => {
  const sidebarContent = jVNavigationDrawer.value?.$el.nextElementSibling.querySelector('.v-navigation-drawer__content');

  sidebarContent.addEventListener('scroll', contentScrollHandler);
  sidebarContent.addEventListener('scrollend', contentScrollHandler);
};

onMounted(() => {
  nextTick(() => {
    loadPopularGames();
    initialScrollListener();
  });
});
</script>

<style lang="scss" scoped>
.j-game-sidebar {
  :deep(.v-navigation-drawer__prepend) {
    order: 1;
  }
  :deep(.v-navigation-drawer__content) {
    @include custom-scrollbar($j-color-primary, $j-bg-block-light);
    margin: 24px 0;
    &::before{
      position: absolute;
      left: 4px;
      right: 4px;
      bottom: 0;
      content: '';
      background: $j-gradient-game-sidebar;
      height: 125px;
      filter: blur(6px);
      z-index: 1;
    }
  }
  :deep(.v-navigation-drawer__append) {
    @include custom-scrollbar($j-color-primary, $j-bg-block-light);
    overflow-y: auto;
    max-height: 140px;
    margin-bottom: 24px;
  }
  &.j-game-sidebar--desktop {
    top: 0 !important;
    height: 100% !important;
    :deep(.v-navigation-drawer__prepend) {
      order: initial;
    }
  }
  &.j-game-sidebar--scroll-fade {
    :deep(.v-navigation-drawer__content) {
      &::before {
        opacity: 1;
        visibility: visible;
        transition: opacity, .5s;
      }
    }
  }
  &.j-game-sidebar--scroll-no-fade {
    :deep(.v-navigation-drawer__content) {
      &::before {
        opacity: 0;
        visibility: hidden;
        transition: opacity, .5s;
      }
    }
  }
}

.j-game-sidebar__btn {
  position: absolute;
  border-radius: 8px 0 0 8px;
  top: 0;
  left: 50%;
  transform: translate(-50%, -80%) rotate(90deg);
  &.j-game-sidebar__btn--desktop {
    border-radius: 0 8px 8px 0;
    top: 50%;
    left: auto;
    right: 0;
    transform: translate(100%, -50%);
    :deep(.v-icon) {
      transform: rotate(180deg);
    }
  }

  &.j-game-sidebar__btn--active {
    :deep(.v-icon) {
      transform: rotate(180deg);
    }
  }
  &.j-game-sidebar__btn--inner {
    z-index: 2;
    :deep(.v-icon) {
      transform: none;
    }
  }
}

.j-game-sidebar__tabs {
  :deep(.v-slide-group__prev),
  :deep(.v-slide-group__next) {
    display: none;
  }
}

.j-game-sidebar__tab {
  border-top: 1px solid $j-tab-border;
  .v-icon :deep(svg path) {
    fill: $j-text-secondary;
  }
  &.j-game-sidebar__tab--desktop {
    border-top: 0;
    border-bottom: 1px solid $j-tab-border;
  }

  &.j-game-sidebar__tab--active {
    .v-icon :deep(svg path) {
      fill: revert-layer;
    }
  }
}

.j-game-sidebar-tournament-prize {
  background-image: url('/assets/images/game/prize-pool.svg');
  width: 295px;
  height: 295px;
}
</style>
