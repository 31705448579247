<template>
  <v-app-bar
    :color="scssVariables.jBgBlock"
    class="j-header"
    height="74"
    flat
  >
    <div class="j-container j-container--fw d-flex align-center px-6 mx-auto">
      <div class="j-header__logo mr-9">
        <nuxt-link
          class="j-link j-link--img"
          :to="useLocalePrefixForHref('/')"
          @click.prevent="useScrollToTop()"
        >
          <v-img
            :width="96"
            :lazy-src="useImgSrc(images['logo_jozz'])"
            :src="useImgSrc(images['logo_jozz'])"
            alt="logo"
          />
        </nuxt-link>
      </div>
      <v-spacer />
      <div class="j-header__services d-flex align-center">
        <template v-if="getUserData.loggedIn">
          <div class="j-logged-user-panel d-inline-flex justify-center align-center flex-column-reverse flex-lg-row">
            <div
              :class="[
                'j-logged-user-panel__block',
                'j-btn',
                'j-btn--height-44',
                'j-btn--gradient-main',
                'text-none',
                'text-body-1',
                'px-9',
                'px-lg-5',
                'ml-lg-4',
                'rounded',
                'd-flex',
                'align-center',
                'justify-center',
              ]"
            >
              <nuxt-link
                :to="useLocalePrefixForHref('/cashier')"
                class="j-link"
              >
                <div class="d-flex">
                  <v-img
                    :width="16"
                    :src="images['auth_shield']"
                    alt="icon auth-shield"
                  />
                  <general-text
                    dictionary-key="general_cashier"
                    class="j-text--white j-text--fs-16 font-weight-medium ml-2"
                  />
                </div>
              </nuxt-link>
              <span class="j-logged-user-panel__main-btn-vertical-line" />
              <nuxt-link
                to="/cabinet"
                class="j-link"
              >
                <general-text
                  :text="getUserData?.screenName || 'user'"
                  class="j-text--white j-text--fs-16 j-text--mw-110 font-weight-medium text-truncate"
                />
              </nuxt-link>
              <v-img
                v-if="getUserData.needDocumentsVerification"
                :width="24"
                :src="images['need-verification']"
                class="j-logged-user-panel__verification-icon"
                alt="icon need-verification"
              />
            </div>
          </div>
        </template>
        <template v-else>
          <general-login-buttons-block />
        </template>
      </div>
    </div>
  </v-app-bar>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useAuthStore } from '~/stores/auth';

const authStore = useAuthStore();
const { getUserData } = storeToRefs(authStore);
const images = useAssetsImages();
const scssVariables = useScssVariables();
</script>

<style lang="scss" scoped>
.j-header {
  position: initial !important;
}
.j-header__logout-btn {
  min-width: auto;
}
.j-logged-user-panel {
  width: 100%;
  @media (min-width: 1280px) {
    width: auto;
  }
}
.j-logged-user-panel__verification-icon {
  position: absolute;
  top: -8px;
  right: -8px;
}
.j-logged-user-panel__block {
  position: relative;
  background-color: $j-color-primary;
}
.j-logged-user-panel__main-btn-vertical-line {
  position: relative;
  margin: 0 10px;
  &:before {
    content: "";
    width: 1px;
    height: 42px;
    position: absolute;
    left: 0px;
    top: -21px;
    background-color: $j-color-btn-main-divider;
  }
}
</style>
