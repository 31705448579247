<template>
  <v-app>
    <v-container
      ref="layoutContainer"
      :style="layoutContainerStyles"
      :class="[
        'j-container',
        'j-container--fw',
        'd-flex',
        'flex-column',
        'pa-0',
        'px-lg-10',
        'ma-auto',
        gameContainerClasses,
      ]"
    >
      <!--Header-->
      <j-header-game v-if="!isMobile" />
      <v-main class="j-main-game">
        <slot />
        <!--Sidebar-->
        <j-sidebar-game />
      </v-main>
      <!--Footer-->
      <v-footer
        v-if="isShowFooter"
        :color="scssVariables.jBgMain"
        class="j-footer-game ma-0 pa-0 justify-center"
      >
        <v-btn
          :to="useLocalePrefixForHref(linkGameReal)"
          variant="flat"
          size="large"
          :color="scssVariables.jBtnRegular"
          :height="54"
          class="text-none text-body-1 px-6 j-btn j-btn--gradient-main mt-8"
        >
          {{ $t('game_play_money') }}
        </v-btn>
      </v-footer>
    </v-container>
  </v-app>
</template>

<script lang="ts" setup>
import { REQUIRED_AUTH_PAGES_WITH_CHILDREN } from '~/constants/general';
import type { GameFrameSizeUpdateParams } from '~/types/pages/game';

const { $eventEmitter } = useNuxtApp();
const scssVariables = useScssVariables();
const isMobile = inject<Ref<boolean>>('isMobile');
const route = useRoute();
const isShowFooter = computed(() =>
  !isMobile?.value && route.params.mode === REQUIRED_AUTH_PAGES_WITH_CHILDREN.game.fun);
const linkGameReal = computed(
  () => `/game/${route.params.gamePrettyName}/${REQUIRED_AUTH_PAGES_WITH_CHILDREN.game.real}`,
);
const isShowSidebar = ref(!isMobile?.value);
const INITIAL_CONTAINER_CLASSES = 'j-container--min-height-100vh pb-lg-10 pt-lg-5';
const gameContainerClasses = ref(INITIAL_CONTAINER_CLASSES);
const layoutContainer = ref<HTMLElement | null>(null);
const layoutContainerStyles = ref({});
/**
 * Updates the width of the layout container based on the size difference between the game container and the game frame.
 * @param {GameFrameSizeUpdateParams} params - The parameters containing game container and game frame widths
 */
const updateLayoutContainerSize = ({
  containerWidth,
  gameFrameWidth,
}: GameFrameSizeUpdateParams) => {
  gameContainerClasses.value = '';
  if (layoutContainer.value) {
    const widthDifference = containerWidth - gameFrameWidth;

    if (widthDifference > 0) {
      const layoutContainerCurrentWidth = layoutContainer.value.$el.offsetWidth;
      const updatedLayoutContainerWidth = layoutContainerCurrentWidth - widthDifference;

      layoutContainerStyles.value = { width: `${updatedLayoutContainerWidth}px` };
    }
  }
};
/**
 * Resets the layout container to its initial size and triggers an update for the game frame size on game page component
 */
const setInitialLayoutContainerSize = (noGameFrameSizeUpdate: boolean = false) => {
  gameContainerClasses.value = INITIAL_CONTAINER_CLASSES;
  layoutContainerStyles.value = { width: '100%' };
  if (!noGameFrameSizeUpdate) {
    $eventEmitter.emit('start-update-game-frame-size');
  }
};

provide('isShowGamePageSidebar', isShowSidebar);

onMounted(() => {
  window.addEventListener('resize', () => setInitialLayoutContainerSize());
  $eventEmitter.on('resize-game-frame', setInitialLayoutContainerSize);
  $eventEmitter.on('game-frame-size-updated', updateLayoutContainerSize);
  $eventEmitter.on('toggle-game-page-sidebar', setInitialLayoutContainerSize);
});

onUnmounted(() => {
  window.removeEventListener('resize', () => setInitialLayoutContainerSize());
  $eventEmitter.off('resize-game-frame', setInitialLayoutContainerSize);
  $eventEmitter.off('game-frame-size-updated', updateLayoutContainerSize);
  $eventEmitter.off('toggle-game-page-sidebar', setInitialLayoutContainerSize);
});
</script>

<style lang="scss" scoped>
.v-application {
  background-color: $j-bg-main;
}

.j-main-game {
  background-color: $j-bg-main;
  position: relative;
  padding: 0;
  flex: 1;
}

.j-footer-game {
  flex-grow: 0;
}
</style>
