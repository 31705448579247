export const TABLES_ITEMS = {
  gameStandings: {
    headers: [
      {
        dictionaryKey: 'general_place',
        fieldName: 'place',
      },
      {
        dictionaryKey: 'general_player',
        fieldName: 'playerName',
      },
      {
        dictionaryKey: 'general_points',
        fieldName: 'score',
      },
    ],
  },
};
